<template>
  <!--
    Export Calendar
  -->
  <div class="onCallDutyExport">
    <template v-if="reportData">
      <LoadingPlaceholder />
      <div class="progress mt-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{width: reportData.percentage + '%'}"
          :aria-valuenow="reportData.percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ reportData.percentage + '%' }}
        </div>
      </div>
    </template>
    <template v-else>
      <form>
        <div class="form-group">
          <label
            for="roles"
            class="form-label"
          >{{
            $t("customerNotification.roles")
          }}</label>
          <Multiselect
            id="roles"
            v-model="selectedRole"
            tag-placeholder="Not found"
            placeholder="Select a Role"
            :options="allRoles"
            select-label=""
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="false"
            :close-on-select="true"
            :custom-label="roleLabel"
            class="mb-4"
          />
        </div>
        <div class="form-group">
          <label
            for="contacts"
            class="form-label"
          >{{
            $t("installationDetailOperationView.contacts")
          }}</label>
          <LoadingPlaceholder v-if="loadingContacts" />
          <Multiselect
            v-else
            id="contacts"
            v-model="selectedContact"
            tag-placeholder="Not found"
            placeholder="Select a Contact"
            :options="contacts"
            select-label=""
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            :custom-label="customLabel"
            class="mb-4"
          />
        </div>
      </form>
      <div class="form-check mb-3">
        <input
          id="lastMonthCheck"
          v-model="lastMonth"
          type="checkbox"
          class="form-check-input"
        >
        <label
          class="form-check-label mr-5"
          for="lastMonethCheck"
        >Last Month</label>
      </div>
      <template v-if="!lastMonth">
        <div class="form-group">
          <label>{{ $t("from") }}</label>
          <date-picker
            v-model="selectedDateFrom"
            :editable="true"
            :clearable="false"
            type="date"
            lang="en"
            format="DD.MM.YYYY"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
          <label class="ml-4">{{ $t("to") }}</label>
          <date-picker
            v-model="selectedDateTo"
            :editable="true"
            :clearable="false"
            type="date"
            lang="en"
            format="DD.MM.YYYY"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import DatePicker from "vue2-datepicker";
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "OnCallDutyExport",
  components: {
    Multiselect: () => import("vue-multiselect"),
    DatePicker,
  },
  mixins: [dateTimeMixin, authenticationMixin],
  props: {
    roles: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      contacts: null,
      selectedContact: null,
      selectedRole: null,
      allRoles: null,
      loadingContacts: true,
      lastMonth: true,
      selectedDateFrom: null,
      selectedDateTo: null,
      progress: 0,
      reportInterval: null,
      reportData: null,
    };
  },
  watch: {
    selectedRole () {
      this.getContacts();
    },
  },
  created () {
    this.allRoles = [...this.roles];
    this.allRoles.unshift({ name: "Any" });
    this.selectedRole = this.allRoles[0];
  },
  beforeDestroy () {
    this.cancelInterval();
  },
  methods: {
    createInterval () {
      this.reportData = { percentage: 0 };
      this.reportInterval = window.setInterval(() => {
        this.getReportProgress();
      }, 2000);
    },
    cancelInterval () {
      this.reportData = null;
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
      this.reportData = null;
    },
    getReportProgress () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios
        .get(`/OnCallDuty/GetOnCallDutyReportData?user=${email}`)
        .then((response) => {
          if (response.status == 200 && response.data) {
            this.reportData = response.data;
            if (this.reportData.report) {
              const value = this.reportData.report;
              const link = document.createElement("a");
              link.href =
                "data:application/octet-stream;charset=utf-8;base64," +
                value;
              link.target = "_blank";
              if (this.lastMonth) {
                link.download =
                  "rufbereitschaft_" + this.moment().format("MMyyyy") + ".xlsx";
              } else {
                link.download =
                  "rufbereitschaft_" +
                  this.toMoment(this.selectedDateFrom).format("DDMMyyyy") +
                  "-" +
                  this.toMoment(this.selectedDateTo).format("DDMMyyyy") +
                  ".xlsx";
              }
              link.click();
              this.cancelInterval();
              this.$emit("closeExport");
            } else if (this.reportData.error) {
              this.$snotify.error(this.reportData.error);
              this.cancelInterval();
              this.$emit("closeExport");
            }
          } else {
            this.reportData = null;
            this.cancelInterval();
            this.$emit("closeExport");
          }
        });
    },
    customLabel ({ firstname, surname }) {
      return `${firstname} ${surname}`;
    },
    roleLabel ({ name }) {
      return `${name}`;
    },
    getContacts () {
      this.selectedContact = null;
      this.loadingContacts = true;
      var rolesForReq = [];
      if (this.selectedRole.name == "Any") {
        rolesForReq = this.roles.map((x) => x.name);
      } else {
        rolesForReq = [this.selectedRole.name];
      }

      this.axios
        .post(`/OnCallDuty/GetOnCallDutyContacts`, rolesForReq)
        .then((response) => {
          this.contacts = response.data;
          this.contacts.unshift({ firstname: "Any", surname: "" });
          this.selectedContact = this.contacts[0];
        })
        .finally(() => {
          this.loadingContacts = false;
        });
    },
    exportCalnedar () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      var req = {
        lastMonth: this.lastMonth,
        contact: this.selectedContact,
        role: this.selectedRole.name,
        user: email,
      };

      if (!req.lastMonth) {
        req.dateFrom = this.selectedDateFrom;
        req.dateTo = this.selectedDateTo;
      }

      this.axios
        .post(`/OnCallDuty/ExportOnCallDuty`, req)
        .then(() => {
          this.createInterval();
        })
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
